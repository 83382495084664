import { Dialog } from "@headlessui/react";
import cs from "classnames";
import { signOut, useSession } from "next-auth/react";
import Image from "next/image";
import React, { useImperativeHandle, useRef, useState } from "react";
import { useWindowScroll } from "react-use";
import {
  useAccount,
  useConnect,
  useDisconnect,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";

import { Button } from "@components/Button";
import {
  LoadingModal,
  LoadingModalHandle,
  Subtitle,
} from "@components/LoadingModal";
import { SearchBar } from "@components/SearchBar";
import { ProfileModal, WalletIcon } from "@components/web3";
import { MarketplaceLink } from "@components/MarketplaceLink";
import { useTheme } from "@hooks/theme";

import EmailIcon from "@public/img/email.svg";
import logoBlack from "@public/img/logo.png";
import logoWhite from "@public/img/logo_white.png";
import MetaMaskIcon from "@public/img/metamask.svg";
import logoSmallBlack from "@public/img/spaceship_icon.png";
import logoSmallWhite from "@public/img/spaceship_icon_white.png";
import WalletConnectIcon from "@public/img/walletConnect.svg";

const connectorIcons: Record<
  string,
  { src: string; width: number; height: number }
> = {
  injected: MetaMaskIcon,
  walletConnect: WalletConnectIcon,
  web3auth: EmailIcon,
};

const connectorLabels: Record<string, string> = {
  Injected: "MetaMask",
  WalletConnect: "WalletConnect",
  Web3Auth: "Connect with email",
};

const ChainLimiter: React.FC = () => {
  const { chain } = useNetwork();
  const { chains, switchNetwork } = useSwitchNetwork();
  const { disconnect } = useDisconnect();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (chain?.unsupported) {
    return (
      <div className="flex flex-col items-center justify-center rounded-md bg-white px-3 py-6 dark:bg-jacarta-700">
        <h2 className="mb-3 min-w-[300px] text-center text-xl dark:text-gray-100">
          Wrong network
        </h2>
        {switchNetwork ? (
          <>
            <Subtitle className="my-6 block text-center">
              Your wallet is currently connected to the wrong network.
            </Subtitle>
            <Button onClick={() => switchNetwork?.(chains[0]?.id)}>
              Change to {chains[0]?.name}
            </Button>
          </>
        ) : (
          <>
            <Subtitle className="my-6 block text-center">
              Try changing to {chains[0]?.name} on your wallet app
            </Subtitle>
            <Button
              onClick={() => {
                disconnect(undefined, {
                  onSuccess() {
                    signOut();
                  },
                });
              }}
            >
              Disconnect
            </Button>
          </>
        )}
      </div>
    );
  }
  return null;
};

export type HeaderHandle = {
  connectWallet: () => void;
};

type NavbarTitleProps = {
  title: string;
  href: string;
};

const NavbarTitle = ({ title, href }: NavbarTitleProps) => (
  <li className="js-nav-dropdown group relative">
    <MarketplaceLink
      href={href}
      className="flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent md:px-5"
      id="navDropdown-1"
    >
      {title}
      <i className="md:hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className="h-4 w-4 dark:fill-white"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
        </svg>
      </i>
    </MarketplaceLink>
  </li>
);

const MarketHeader = React.forwardRef<HeaderHandle>((props, ref) => {
  const { chain } = useNetwork();
  const { y } = useWindowScroll();
  const { connect, connectors } = useConnect();
  const { disconnect } = useDisconnect();
  const [isOpen, setIsOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { handleTheme } = useTheme();
  const { isConnected } = useAccount();
  const { data: session } = useSession();
  const loaderRef = useRef<LoadingModalHandle>(null);
  useImperativeHandle(ref, () => ({
    connectWallet: () => {
      setIsOpen(true);
    },
  }));
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeProfileModal() {
    setIsProfileModalOpen(false);
  }

  function openProfileModal() {
    setIsProfileModalOpen(true);
  }

  return (
    <>
      <header
        className={cs(
          "js-page-header fixed top-0 z-40 w-full backdrop-blur transition-colors",
          {
            "js-page-header--is-sticky": y > 0,
          }
        )}
      >
        <div className="flex items-center px-6 py-6 xl:px-24">
          <MarketplaceLink href="/" className="shrink-0">
            <span className="hidden dark:hidden xs:block">
              <Image
                height={32}
                width={157}
                alt="Spaceship | NFT Marketplace"
                src={logoBlack}
                priority
              />
            </span>
            <span className="block dark:hidden xs:hidden">
              <Image
                height={33}
                width={33}
                alt="Spaceship | NFT Marketplace"
                src={logoSmallBlack}
                priority
              />
            </span>
            <span className="hidden dark:block dark:xs:hidden">
              <Image
                height={33}
                width={33}
                alt="Spaceship | NFT Marketplace"
                src={logoSmallWhite}
              />
            </span>
            <span className="hidden  dark:xs:block">
              <Image
                height={34}
                width={171}
                alt="Spaceship | NFT Marketplace"
                src={logoWhite}
              />
            </span>
          </MarketplaceLink>
          <div className="mr-5 ml-5 hidden w-full md:block">
            <SearchBar />
          </div>
          <div className="invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 dark:bg-jacarta-800 md:visible md:relative md:inset-auto md:flex md:bg-transparent md:opacity-100 dark:md:bg-transparent">
            <nav className="navbar w-full">
              <ul className="flex flex-col md:flex-row">
                <NavbarTitle title="Home" href="/" />
                <NavbarTitle title="Create" href="/create" />
                <NavbarTitle title="Exchange" href="/exchange" />
              </ul>
            </nav>

            <div className="ml-8 hidden sm:flex xl:ml-12">
              <WalletIcon onClick={openModal} loader={loaderRef} />
              <div
                role="button"
                onClick={handleTheme}
                className="js-dark-mode-trigger group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="dark-mode-light h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z" />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="dark-mode-dark hidden h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="ml-auto flex md:hidden">
            {session && (
              <button
                onClick={openProfileModal}
                className="group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
                aria-label="profile"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                </svg>
              </button>
            )}
            {/* Dark Mode */}
            <div
              role="button"
              onClick={handleTheme}
              className="js-dark-mode-trigger group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="dark-mode-light h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="dark-mode-dark hidden h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z" />
              </svg>
            </div>
            {/* Mobile Menu Toggle */}
            <button
              className="js-mobile-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
              aria-label="open mobile menu"
              onClick={() => setIsOpenModal(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
              </svg>
            </button>
          </div>
        </div>
      </header>
      <Dialog
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        className="relative z-50 flex"
      >
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto rounded bg-white">
            <Dialog.Title className="py-4 text-center text-lg font-semibold"></Dialog.Title>
            <Dialog.Description className="divide-y-2 py-3 text-center text-sm font-extralight text-gray-700"></Dialog.Description>
            <div className="js-mobile-menu nav-menu--is-open invisible fixed inset-0 z-10 ml-auto flex items-center bg-white opacity-0 dark:bg-jacarta-800">
              {/* Mobile Logo / Menu Close */}
              <div className="t-0 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 dark:bg-jacarta-800 lg:hidden">
                {/* Mobile Logo */}
                <MarketplaceLink href="/" className="shrink-0">
                  <span className="hidden dark:hidden xs:block">
                    <Image
                      height={32}
                      width={157}
                      alt="Spaceship | NFT Marketplace"
                      src={logoBlack}
                    />
                  </span>
                  <span className="block dark:hidden xs:hidden">
                    <Image
                      height={33}
                      width={33}
                      alt="Spaceship | NFT Marketplace"
                      src={logoSmallBlack}
                    />
                  </span>
                  <span className="hidden dark:block dark:xs:hidden">
                    <Image
                      height={33}
                      width={33}
                      alt="Spaceship | NFT Marketplace"
                      src={logoSmallWhite}
                    />
                  </span>
                  <span className="hidden  dark:xs:block">
                    <Image
                      height={34}
                      width={171}
                      alt="Spaceship | NFT Marketplace"
                      src={logoWhite}
                    />
                  </span>
                </MarketplaceLink>
                {/* Mobile Menu Close */}
                <button
                  className="js-mobile-close group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
                  aria-label="close mobile menu"
                  onClick={() => setIsOpenModal(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                  </svg>
                </button>
              </div>
              {/* Mobile Search */}
              <div className="mt-24 mb-6 flex w-full">
                <SearchBar placeholder="Search" />
              </div>
              {/* Primary Nav */}
              <nav className="navbar w-full">
                <ul className="flex flex-col">
                  <li className="js-nav-dropdown group relative">
                    <MarketplaceLink
                      href="/"
                      className="dropdown-toggle flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent"
                      id="navDropdown-1"
                      aria-expanded="true"
                      role="button"
                    >
                      Home
                    </MarketplaceLink>
                  </li>
                  <li className="group">
                    <MarketplaceLink
                      href="/create"
                      className="flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent"
                    >
                      Create
                    </MarketplaceLink>
                  </li>
                  <li className="group">
                    <MarketplaceLink
                      href="/exchange"
                      className="flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent"
                    >
                      Exchange
                    </MarketplaceLink>
                  </li>
                </ul>
              </nav>
              {/* Mobile Connect Wallet / Socials */}
              {!isConnected && (
                <div className="mt-10 w-full">
                  <button
                    className="js-wallet block w-full rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                    onClick={openModal}
                  >
                    Connect Wallet
                  </button>
                </div>
              )}
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
      <Dialog open={isOpen} onClose={closeModal} className="relative z-50">
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          {/* The actual dialog panel  */}
          <Dialog.Panel className="mx-auto w-full max-w-xs rounded bg-white">
            <Dialog.Title className="py-4 text-center text-lg font-semibold">
              Connect your wallet
            </Dialog.Title>
            <Dialog.Description className="divide-y-2 py-3 text-center text-sm font-extralight text-gray-700">
              Select your desired wallet
            </Dialog.Description>
            {connectors.map((connector) => {
              const icon = connectorIcons[connector.id];
              if (!icon) return null;
              return (
                <div
                  key={connector.id}
                  role={"button"}
                  className="p2 flex flex-col items-center justify-center py-5  hover:bg-slate-200"
                  onClick={() => {
                    connect({ connector });
                    closeModal();
                  }}
                >
                  <h3 className="mb-3 text-md text-zinc-800">
                    {connectorLabels[connector.name] || connector.name}
                  </h3>
                  <div className="w-20">
                    <Image src={icon} alt={`${connector.name} logo`} />
                  </div>
                </div>
              );
            })}
          </Dialog.Panel>
        </div>
      </Dialog>
      <Dialog
        open={!!chain?.unsupported}
        onClose={() => {
          disconnect(undefined, {
            onSuccess: () => {
              signOut();
            },
          });
        }}
        className="relative z-50"
      >
        <div>Wrong network mate</div>
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center">
          <ChainLimiter />
        </div>
      </Dialog>
      <ProfileModal isOpen={isProfileModalOpen} onClose={closeProfileModal} />
      <LoadingModal ref={loaderRef} />
    </>
  );
});
MarketHeader.displayName = "MarketHeader";
export { MarketHeader };
